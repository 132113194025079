@import "@/assets/scss/var.scss";
.profile{
    .el-form-item{
        margin-bottom: 0;
    }
    .pg-title {
        font-size: $fontsize-lg;
    }
    /deep/ .avatar-uploader{
        .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        .el-upload:hover {
            border-color: $border-primary;
        }
        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
        }
        .avatar {
            width: 178px;
            height: 178px;
            display: block;
            object-fit: cover;
        }
        .el-upload:hover {
            border-color: $border-primary;
         }
         .el-upload--picture-card:hover, .el-upload:focus {
            border-color: $border-primary;
            color: $color-primary;
        }
    }
    &__content{
        font-size: $fontsize-sm;
        margin-top: $offset-v-lg;
        display: flex;
    }
    &__info{
        flex:1;
        >div{
            display: flex;
            align-items: center;
        }
        >div+div{
            margin-top: $offset-v-lg;
        }
    }
    &__type{
        display: flex;
        flex: 1;
        align-items: center;
        justify-content: space-between;
        margin-right: 10px;
        height: 15px;
        &-addon{
            .icon {
                width: 1.25em;
                height: 1.25em;
                margin-right: 15px;
                vertical-align: middle;
                // margin-bottom: 5px;
            }
        }
    }
    &__upload{
        margin-left: 50px;
        &-tips{
            max-width: 180px;
            margin-top: $offset-v;
        }
    }
    &-label{
        width: 150px;
        color: #999;
    }
    &__bottom{
        text-align: center;
        margin-top: 30px;
        .el-button{
            min-width: 100px;
        }
    }
}  
